import { post, get, ResponseType, URL } from "@/utils/axios";
import {WELFARE_BOOK_RATING_IMPORT} from "@/utils/api/url";

//获取书籍评级
export const getLevels = () => {
    return get(URL.WELFARE_BOOK_RATING_LEVELS)
}

//获取书籍评级月份
export const getMonths = () => {
    return get(URL.WELFARE_BOOK_RATING_MONTHS)
}

//获取书籍评级信息列表
export const getList = (params: {
    bookId: number | string ,
    month: string ,
    level: string ,
    page: number ,
    size: number ,
}) => {
    return get(URL.WELFARE_BOOK_RATING_LIST, {params})
}

//删除
export const doDelete = (data: {
    id: number
}) => {
    return post(URL.EDITOR_BOOK_UPDATE_MONITORING_DELETE, {data})
}

//导入
export const doImport = (data: any
) => {
    return post(URL.WELFARE_BOOK_RATING_IMPORT, {data})
}

//获取书籍评级月份
export const getTemplate = () => {
    return get(URL.WELFARE_BOOK_RATING_TEMPLATE)
}